


















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import StudentForm from './student-form.vue';

class PageStudentRequest extends PageRequest {
    name: string = '';
    document: string = '';
    type: number = null;
    //group: number = null;
}

@Component({
    components: { ListView, StudentForm, FormBase }
})
export default class Students extends ListBase {
    entity: string = 'student';
    pagerequest: PageStudentRequest = new PageStudentRequest();
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'radio', prop: 'type', label: 'Tipo de Asistente', generalType: 'T_STUDENT', inline: false, required: true, size: 6, row: true, defaultValue: '0', onchange: this.afterSave, hideDetails: true }),
        //this.gItem({ type: 'object', prop: 'group', label: 'Grupo', size: 2, onchange: this.afterSave, returnObject: false, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'document', label: 'Buscar por DNI', size: 2, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por Nombre', size: 4, onchange: this.afterSave, hideDetails: true }),
    ]

    columns = [
        this.gCol('code', 'Codigo', 'text'),
        this.gCol('document_full', 'Documento', 'text'),
        this.gCol('phone_mobile', 'Telefono', 'text'),
        this.gCol('fullname', 'Apellidos y Nombres', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]

    get exluddeIds() {
        let ids = [Util.abp.setting.get('DEFAULT_CUSTOMER')];
        return { edit: ids, delete: ids };
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        if (list != undefined)
            await list.getpage();
    }     

    mounted() {
        
    }

    created() {
        
    }
}