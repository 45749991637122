








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Student from '@/store/entities/students/student';
import Addresses from './addresses.vue';

@Component({
    components: { FormBase, ModalView, Addresses },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                //if (obj.action.indexOf('update') >= 0) {
                //    obj.model = Util.extend(true, {}, this.$store.state.student.editModel);
                //}
                //obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).initForm();
            }
        }
    }
})

export default class StudentForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    student: Student = new Student();
    modalSetting: any = {
        entity: "student",
        title: 'Asistente',
        width: "1000px",
        //hasAddress: true
    };
    
    async getLastCode() { //evento para consultar el ultimo codigo disponible para clientes                
        let form = (this as any).$refs.form.$refs.entityForm;
        let code = await this.$store.dispatch({
            type: 'student/getLastCode'
        });
        form.model.code = code;
    }
    
    items = [
        this.gItem({ type: 'radio', prop: 'type_id', label: 'Tipo de Asistente', generalType: 'T_STUDENT', required: true, size: 10, row: true, defaultValue: '0' }),
        this.gItem({ type: 'active', size: 2 }),
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', required: true, readonly: true, size: 3, icon: 'cached', appendEvent: this.getLastCode, onenter: this.getLastCode }),
        this.gItem({ type: 'object', prop: 'document_type_id', label: 'Tipo de Documento', required: true, size: 3 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Documento', required: true, size: 3 }),
        this.gItem({ type: 'object', prop: 'gender_id', label: 'Genero', size: 3 }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'lastname', label: 'Apellidos', required: true, size: 6 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Numero de Telefono', size: 6 }),
        this.gItem({ type: 'date', prop: 'birthdate', label: 'Fecha de Nacimiento', size: 6 }),
        this.gItem({ type: 'object', prop: 'group_id', label: 'Grupo', size: 6 }),
        //this.gItem({ type: 'divider' }),
        //this.gItem({ type: 'array', prop:'addresses' })
    ]

    initForm() {            
        let $this = (this as any);
        let model = this.$store.state.student.editModel;
        if (model.id > 0) {                
            
        } else {
            setTimeout(() => {
                this.getLastCode();
            }, 100);                
        }

        //this.$store.commit('address/setList', model.addresses);
        //if ($this.$refs.formAddr != undefined)
        //    $this.$refs.formAddr.initForm();

    }

    async save() {
        this.$emit('save-success');
        this.$emit('input', false);
    }

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
