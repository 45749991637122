














    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import FormBase from '@/components/helper/form-base.vue';
    import ModalView from '@/components/helper/modal-view.vue';
    import ListBase from '@/lib/listbase';
import util from '@/lib/util';
    
    @Component({
        components: { FormBase, ModalView },
        data() {
            return {
                //modalShow: false
            }
        },
    })

    export default class Addresses extends ListBase {
        entity: string = 'address';
        tabModel: any = null;
        formBase: boolean = true;

        get list() {
            return this.$store.state.address.list;
        };
                
        addrMeths = {
            country: (country, id) => {
                let cid = (typeof country == "object" ? country.id : country);
                let depts = this.$store.state.department.activeList.filter((op: any) => {
                    return cid == op.country_id;
                }); 

                this.$store.commit('address/setDepts', { index: id, list: depts} );
            },
            department: (department, id) => {
                if (department == null) return;
                let cid = (typeof department == "object" ? department.id : department);
                let dept = util.findByParam(this.$store.state.department.activeList, 'id', cid)[0]; 
                if (dept == null) return;
                this.$store.commit('address/setProvs', { index: id, list: dept.provinces });
            },
            province: (province, id) => {
                if (province == null) return;
                let cid = (typeof province == "object" ? province.id : province);
                let prov = util.findByParam(this.$store.state.address['activeProvs' + id], 'id', cid)[0];
                if (prov == null) return;
                this.$store.commit('address/setDists', { index: id, list: prov.districts });
            },
        }

        gAddrItems(index) {
            let result: any = [];

            result.push(this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', returnObject: false, entity: 'country', size: 3 })); 
            result.push(this.gItem({ type: 'object', prop: 'department_id', label: 'Departamento', returnObject: false, entity: 'address', sourceList: 'activeDepts' + index, size: 3, loadOptions: false })); 
            result.push(this.gItem({ type: 'object', prop: 'province_id', label: 'Provincia', returnObject: false, entity: 'address', sourceList: 'activeProvs' + index, size: 3, loadOptions: false })); 
            result.push(this.gItem({ type: 'object', prop: 'district_id', label: 'Distrito', returnObject: false, entity: 'address', sourceList: 'activeDists' + index, size: 3, loadOptions: false }));
            result.push(this.gItem({ type: 'text', prop: 'address1', label: 'Direccion 1', preicon: 'home', size: 6 }));
            result.push(this.gItem({ type: 'text', prop: 'address2', label: 'Direccion 2', preicon: 'home', size: 6 }));
            result.push(this.gItem({ type: 'text', prop: 'other', label: 'Alias', size: 3 }));
            result.push(this.gItem({ type: 'text', prop: 'postcode', label: 'Codigo Postal', size: 3 }));
            result.push(this.gItem({ type: 'phone', prop: 'phone', label: 'Telefono', size: 3 }));
            result.push(this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Celular', preicon: 'smartphone', size: 3 }));
            return result;
        };
               
        items = []

        handleChange(newVal, oldVal) {
            this.addrMeths.country(newVal.country_id, newVal.index);
            this.addrMeths.department(newVal.department_id, newVal.index);
            this.addrMeths.province(newVal.province_id, newVal.index);
            this.$store.commit('student/setAddresses', this.$store.state.address.list);
        }

        initForm() {
            let $this = (this as any);
            this.list.forEach((ad, index) => {
                this.items[ad.id] = this.gAddrItems(index);

                this.$watch(() => ad, this.handleChange, { deep: true });

                if (ad != null && ad.id > 0) {
                    let department = null;
                    let province = null;
                    if (ad.country_id != null) this.addrMeths.country(ad.country_id, index);
                    if (ad.department_id != null) {
                        department = ad.department_id;
                        if (Number.isInteger(ad.department_id)) {
                            department = this.$store.state.department.activeList.filter((op: any) => {
                                return ad.department_id == op.id;
                            })[0]; 
                        }
                        this.addrMeths.department(department, index);
                    }
                    if (ad.province_id != null && department != null) {
                        province = ad.province_id;
                        if (Number.isInteger(ad.province_id)) {
                            province = department.provinces.filter((op: any) => {
                                return ad.province_id == op.id;
                            })[0];
                        }
                        this.addrMeths.province(province, index);
                    }
                }
            });
        }        

        mounted() {
            this.initForm();
        }

        async created() {
            await this.$store.dispatch({
                type: 'department/getAllActive'
            });
        }
    }
